.App {
  text-align: center;
  height: inherit;
}

.devinfo {
  font-size: 12px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  opacity: 0.5;
  padding-right: 5px;
  padding-left: 5px;
  position: fixed; 
  bottom: 0; 
  right: 0;
}

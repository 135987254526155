#map-wrapper { 
    /* position: relative; */
    margin: 0;
    padding-top: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    /* z-index: -1; */
}

.leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
}
